import React, { useState } from 'react';
import QuestionEditor from './QuestionEditor';

const QuizEditor = ({ quiz, subject, level, quizID }) => {
  const [showAnswer, setShowAnswer] = useState(false);
  const [status] = useState(quiz.status);

  return (
    <div className="container-fluid py-4">
      {/* Header Card */}
      <div className="card bg-palette border-0 shadow-sm mb-4">
        <div className="card-body">
          <div className="d-flex flex-wrap justify-content-between align-items-center gap-3 mb-4">
            {/* Title and Status */}
            <div className="d-flex align-items-center gap-3">
              <h1 className="h3 text-theme mb-0">{quiz.title}</h1>
              <span className={`badge ${status === 'hidden' ? 'bg-secondary' : 'bg-success'}`}>
                <i className={`fa-solid ${status === 'hidden' ? 'fa-eye-slash' : 'fa-eye'} me-2`}></i>
                {status === 'hidden' ? 'Private' : 'Public'}
              </span>
            </div>

            {/* Controls */}
            <div className="d-flex flex-wrap align-items-center gap-3">
              {/* Show Answer Toggle */}
              <div className="form-check form-switch">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="showAnswerSwitch"
                  checked={showAnswer}
                  onChange={() => setShowAnswer(!showAnswer)}
                />
                <label className="form-check-label text-theme" htmlFor="showAnswerSwitch">
                  Show Answers
                </label>
              </div>

              {/* Action Buttons */}
              <div className="btn-group">
                <a href={`/quizzes/${quizID}`} className="btn btn-success">
                  <i className="fa-solid fa-chalkboard-user me-2"></i>Study Mode
                </a>
                <a href={`/quizzes/${quizID}/edit`} className="btn btn-outline-info">
                  <i className="fa-solid fa-pen-to-square me-2"></i>Edit Quiz
                </a>
              </div>
            </div>
          </div>

          {/* Stats Row */}
          <div className="row g-4">
            <div className="col-12 col-sm-6 col-lg-3">
              <div className="card bg-generated border-0">
                <div className="card-body">
                  <h6 className="text-theme-secondary mb-2">
                    <i className="fa-solid fa-list-ol me-2"></i>Questions
                  </h6>
                  <h3 className="text-theme mb-0">{quiz.questions.length}</h3>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3">
              <div className="card bg-generated border-0">
                <div className="card-body">
                  <h6 className="text-theme-secondary mb-2">
                    <i className="fa-solid fa-book me-2"></i>Subject
                  </h6>
                  <h3 className="text-theme mb-0">{subject}</h3>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3">
              <div className="card bg-generated border-0">
                <div className="card-body">
                  <h6 className="text-theme-secondary mb-2">
                    <i className="fa-solid fa-layer-group me-2"></i>Level
                  </h6>
                  <h3 className="text-theme mb-0">{level}</h3>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3">
              <div className="card bg-generated border-0">
                <div className="card-body">
                  <h6 className="text-theme-secondary mb-2">
                    <i className="fa-solid fa-eye me-2"></i>Status
                  </h6>
                  <h3 className="text-theme mb-0">
                    {status.charAt(0).toUpperCase() + status.slice(1)}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Questions List */}
      <div className="questions-list">
        {quiz.questions.map((question, index) => (
          <div key={question.id} className="position-relative mb-4">
            <div className="position-absolute start-0 top-0 translate-middle-y
                          d-flex align-items-center justify-content-center
                          bg-success rounded-circle text-white"
                 style={{ width: '32px', height: '32px', marginLeft: '-20px' }}>
              {index + 1}
            </div>
            <QuestionEditor
              question={question}
              showAnswer={showAnswer}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default QuizEditor;
