import React, { useState } from 'react';
import axios from 'axios';
import { setupAxiosCSRF } from '../../helpers';
import AnswerEditor from './AnswerEditor';

const QuestionEditor = ({ question, showAnswer }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [content, setContent] = useState(question.content);
  const [explanation, setExplanation] = useState(question.explanation || '');
  const [answers, setAnswers] = useState(question.answers);
  const [errors, setErrors] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);

  const handleEditClick = () => {
    setIsEditing(true);
    setSaveSuccess(false);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setContent(question.content);
    setExplanation(question.explanation || '');
    setAnswers(question.answers);
    setSaveSuccess(false);
  };

  const handleAnswerChange = (id, updatedAnswer) => {
    setAnswers((prevAnswers) =>
      prevAnswers.map((answer) =>
        answer.id === id ? { ...answer, ...updatedAnswer } : answer
      )
    );
  };

  const handleSaveClick = async () => {
    try {
      setIsSaving(true);
      setupAxiosCSRF();
      const response = await axios.patch(`/questions/${question.id}`, {
        question: {
          content,
          explanation,
          answers_attributes: answers.map(({ id, content, is_correct }) => ({
            id,
            content,
            is_correct,
          })),
        },
      });

      if (response.status === 200) {
        setContent(response.data.content);
        setExplanation(response.data.explanation);
        setAnswers(response.data.answers);
        setIsEditing(false);
        setErrors(null);
        setSaveSuccess(true);
        setTimeout(() => setSaveSuccess(false), 3000);
      }
    } catch (error) {
      if (error.response.data.errors) {
        setErrors(error.response.data.errors);
      }
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className={`card bg-palette border-0 shadow-sm mb-4 ${saveSuccess ? 'border-success' : ''}`}>
      {isEditing ? (
        <div className="card-body">
          {/* Question Content */}
          <div className="mb-4">
            <label className="form-label text-theme">
              <i className="fa-solid fa-question me-2"></i>Question
            </label>
            <textarea
              value={content}
              onChange={(e) => setContent(e.target.value)}
              className="form-control bg-palette text-theme border"
              rows="2"
              placeholder="Enter question text..."
            />
          </div>

          {/* Answer Options */}
          <div className="mb-4">
            <label className="form-label text-theme">
              <i className="fa-solid fa-list-ol me-2"></i>Answer Options
            </label>
            <div className="answers-container">
              {answers.map((answer, index) => (
                <div key={answer.id} className="mb-3">
                  <AnswerEditor
                    answer={answer}
                    onAnswerChange={handleAnswerChange}
                    label={String.fromCharCode(65 + index)}
                  />
                </div>
              ))}
            </div>
          </div>

          {/* Explanation */}
          <div className="mb-4">
            <label className="form-label text-theme">
              <i className="fa-solid fa-circle-info me-2"></i>Explanation
            </label>
            <textarea
              value={explanation}
              onChange={(e) => setExplanation(e.target.value)}
              className="form-control bg-palette border text-theme"
              rows="3"
              placeholder="Enter explanation..."
            />
          </div>

          {/* Error Messages */}
          {errors && (
            <div className="alert alert-danger">
              <i className="fa-solid fa-circle-exclamation me-2"></i>
              {errors.map((error, index) => (
                <div key={index}>{error}</div>
              ))}
            </div>
          )}

          {/* Action Buttons */}
          <div className="d-flex justify-content-end gap-2">
            <button
              onClick={handleCancelClick}
              className="btn btn-outline-secondary"
              disabled={isSaving}
            >
              <i className="fa-solid fa-xmark me-2"></i>Cancel
            </button>
            <button
              onClick={handleSaveClick}
              className="btn btn-success"
              disabled={isSaving}
            >
              {isSaving ? (
                <div>
                  <span className="spinner-border spinner-border-sm me-2"></span>
                  Saving...
                </div>
              ) : (
                <div>
                  <i className="fa-solid fa-save me-2"></i>Save
                </div>
              )}
            </button>
          </div>
        </div>
      ) : (
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-start mb-2">
            {/* Question Content */}
            <div className="question-content flex-grow-1 text-theme">
              <p className="mb-0">{question.content}</p>
            </div>

            {/* Action Buttons */}
            <div className="d-flex align-items-center ms-3">
              {saveSuccess && (
                <div className="text-success me-3">
                  <i className="fa-solid fa-check-circle fa-lg"></i>
                </div>
              )}
              <button
                onClick={handleEditClick}
                className="btn btn-link text-success p-0"
              >
                <i className="fa-solid fa-pencil fa-lg"></i>
              </button>
            </div>
          </div>

          {/* Answer Options */}
          <div className="answers-list mb-4">
            {answers.map((answer, index) => (
              <div
                key={answer.id}
                className={`answer-item p-1 mb-1 rounded ${
                  showAnswer && answer.is_correct
                    ? 'bg-success bg-opacity-25 border border-success'
                    : 'bg-darker'
                }`}
              >
                <div className="d-flex align-items-center">
                  <div className="answer-label me-3 bg-secondary bg-opacity-25
                                rounded-circle d-flex align-items-center justify-content-center"
                       style={{ width: '28px', height: '28px' }}>
                    <span className="text-theme">{String.fromCharCode(65 + index)}</span>
                  </div>
                  <span className="text-theme">{answer.content}</span>
                  {showAnswer && answer.is_correct && (
                    <i className="fa-solid fa-check text-success ms-auto"></i>
                  )}
                </div>
              </div>
            ))}
          </div>

          {/* Explanation */}
          {showAnswer && explanation && (
            <div className="explanation bg-info bg-opacity-10 border border-info rounded p-3">
              <div className="d-flex align-items-center mb-2">
                <i className="fa-solid fa-circle-info text-info me-2"></i>
                <h6 className="mb-0 text-theme">Explanation</h6>
              </div>
              <p className="mb-0 text-theme">{explanation}</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default QuestionEditor;
