import React, { useState } from 'react';

const AnswerEditor = ({ answer, onAnswerChange, label }) => {
  const [content, setContent] = useState(answer.content);
  const [is_correct, setIsCorrect] = useState(answer.is_correct);

  const handleContentChange = (e) => {
    const newContent = e.target.value;
    setContent(newContent);
    onAnswerChange(answer.id, { content: newContent, is_correct });
  };

  const handleCorrectChange = (e) => {
    const newCorrect = e.target.checked;
    setIsCorrect(newCorrect);
    onAnswerChange(answer.id, { content, is_correct: newCorrect });
  };

  return (
    <div className="card bg-generated border-0">
      <div className="card-body p-3">
        <div className="d-flex align-items-center gap-3">
          {/* Answer Label */}
          <div className="answer-label bg-info bg-opacity-25 rounded-circle
                        d-flex align-items-center justify-content-center flex-shrink-0"
               style={{ width: '32px', height: '32px' }}>
            <span className="text-theme fw-bold">{label}</span>
          </div>

          {/* Answer Input */}
          <div className="flex-grow-1 position-relative">
            <small className="bottom-0 text-theme" style={{ fontSize: '0.75rem' }}>
              {content.length}/200
            </small>
            <input
              type="text"
              value={content}
              onChange={handleContentChange}
              className="form-control bg-palette text-theme border"
              placeholder="Enter answer option..."
            />
          </div>

          {/* Correct Answer Toggle */}
          <div className="form-check form-switch flex-shrink-0">
            <input
              type="checkbox"
              className="form-check-input"
              checked={is_correct}
              onChange={handleCorrectChange}
              id={`correct-toggle-${answer.id}`}
            />
            <label
              className="form-check-label text-theme"
              htmlFor={`correct-toggle-${answer.id}`}
            >
              {is_correct ? (
                <span className="text-success">
                  <i className="fa-solid fa-check me-1"></i>Correct
                </span>
              ) : (
                <span className="text-theme">
                  <i className="fa-solid fa-times me-1"></i>Incorrect
                </span>
              )}
            </label>
          </div>
        </div>

        {/* Correct Answer Indicator */}
        {is_correct && (
          <div className="position-absolute top-0 end-0 translate-middle">
            <span className="badge rounded-pill bg-success">
              <i className="fa-solid fa-check"></i>
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default AnswerEditor;
