import React, { useState, useEffect } from 'react';

const InspirationalQuote = () => {
  const [quote, setQuote] = useState('');
  const [fadeIn, setFadeIn] = useState(true);

  const quotes = [
    {
      text: "The best way to predict the future is to create it.",
      author: "Peter Drucker"
    },
    {
      text: "Education is not preparation for life; education is life itself.",
      author: "John Dewey"
    },
    {
      text: "The beautiful thing about learning is that no one can take it away from you.",
      author: "B.B. King"
    },
    {
      text: "The more that you read, the more things you will know, the more that you learn, the more places you'll go.",
      author: "Dr. Seuss"
    },
    {
      text: "Live as if you were to die tomorrow. Learn as if you were to live forever.",
      author: "Mahatma Gandhi"
    },
    {
      text: "Knowledge is power. Information is liberating. Education is the premise of progress.",
      author: "Kofi Annan"
    },
    {
      text: "The best way to predict the future is to create it.",
      author: "Peter Drucker"
    },
    {
      text: "Success is not the key to happiness. Happiness is the key to success.",
      author: "Albert Schweitzer"
    },
    {
      text: "Believe you can and you're halfway there.",
      author: "Theodore Roosevelt"
    },
    {
      text: "The only limit to our realization of tomorrow is our doubts of today.",
      author: "Franklin D. Roosevelt"
    },
    {
      text: "Your time is limited, don't waste it living someone else's life.",
      author: "Steve Jobs"
    },
    {
      text: "The most important point is to accept yourself and stand on your two feet.",
      author: "Shunryu Suzuki"
    },
    {
      text: "The only way to do great work is to love what you do.",
      author: "Steve Jobs"
    },
    {
      text: "The best preparation for tomorrow is doing your best today.",
      author: "H. Jackson Brown Jr."
    },
    {
      text: "Change is inevitable but personal growth is a choice.",
      author: "Bob Proctor"
    },
    {
      text: "People seldom do what they believe in. They do what is convenient, then repent.",
      author: "Bob Dylan"
    },
    {
      text: "The only way to achieve the impossible is to believe it is possible.",
      author: "Charles Kingsleigh"
    },
    {
      text: "The only person you are destined to become is the person you decide to be.",
      author: "Ralph Waldo Emerson"
    },
    {
      text: "Life can be found only in the present moment. The past is gone, the future is not yet here, and if we do not go back to ourselves in the present moment, we cannot be in touch with life.",
      author: "Thich Nhat Hanh"
    },
    {
      text: "Success is not final, failure is not fatal: it is the courage to continue that counts.",
      author: "Winston Churchill"
    },
    {
      text: "Action is the foundational key to all success.",
      author: "Pablo Picasso"
    },
    {
      text: "Focused, hard work is the real key to success.",
      author: "John Carmack"
    },
    {
      text: "Work harder on yourself than you do on your job.",
      author: "Jim Rohn"
    },
    {
      text: "If you don't find the time, if you don't do the work, you don't get the results.",
      author: "Arnold Schwarzenegger"
    }
  ];

  useEffect(() => {
    const getRandomQuote = () => {
      setFadeIn(false); // Start fade out

      setTimeout(() => {
        const randomIndex = Math.floor(Math.random() * quotes.length);
        setQuote(quotes[randomIndex]);
        setFadeIn(true); // Start fade in
      }, 500); // Half of the transition time
    };

    // Display initial quote
    getRandomQuote();

    const quoteInterval = setInterval(getRandomQuote, 5000); // Changed to 5 seconds

    return () => clearInterval(quoteInterval);
  }, []);

  return (
    <div
      aria-live="polite"
      className={`quote-container ${fadeIn ? 'fade-in' : 'fade-out'}`}
    >
      <div className="quote-content bg-darker rounded-3 p-4">
        <div className="quote-icon mb-3">
          <i className="fas fa-quote-left text-primary fa-2x opacity-50"></i>
        </div>

        {quote && (
          <div>
            <blockquote className="text-theme mb-3">
              {quote.text}
            </blockquote>

            <figcaption className="text-theme-secondary">
              <i className="fas fa-minus me-2"></i>
              {quote.author}
            </figcaption>
          </div>
        )}
      </div>
    </div>
  );
};

export default InspirationalQuote;

const styles = `
.quote-container {
  transition: opacity 0.5s ease-in-out;
}

.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}

.quote-content {
  position: relative;
  border: 1px solid var(--border-color);
}

.quote-icon {
  display: flex;
  justify-content: center;
}

blockquote {
  font-size: 1.125rem;
  line-height: 1.6;
  font-weight: 500;
  text-align: center;
  margin: 0;
  padding: 0;
}

figcaption {
  text-align: center;
  font-size: 0.875rem;
  font-weight: 500;
}

/* Animation for quote icon */
.quote-icon i {
  transform-origin: center;
  animation: quoteFloat 3s ease-in-out infinite;
}

@keyframes quoteFloat {
  0% { transform: translateY(0) rotate(0); }
  50% { transform: translateY(-5px) rotate(5deg); }
  100% { transform: translateY(0) rotate(0); }
}

/* Mobile Optimizations */
@media (max-width: 768px) {
  blockquote {
    font-size: 1rem;
  }

  .quote-icon i {
    font-size: 1.5rem;
  }
}
`;

// Add styles to document
const styleSheet = document.createElement("style");
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);
