import React, { useEffect, useRef } from 'react'
import Chart from 'chart.js/auto'

const StudentDashboard = ({ user }) => {
 const subjectChartRef = useRef(null)
 const timeChartRef = useRef(null)
 const overallStatsRef = useRef(null)

 useEffect(() => {
   let charts = []

   const fetchData = async () => {
     const [statsRes, subjectRes, timeRes] = await Promise.all([
       fetch('/api/v1/analytics/student_performance/overall_stats').then(r => r.json()),
       fetch('/api/v1/analytics/student_performance/subject_performance').then(r => r.json()),
       fetch('/api/v1/analytics/student_performance/time_analytics').then(r => r.json())
     ])

     // Subject Performance Chart
     const subjectChart = new Chart(subjectChartRef.current, {
       type: 'bar',
       data: {
         labels: subjectRes.data.map(d => d.name),
         datasets: [{
           label: 'Average Score (%)',
           data: subjectRes.data.map(d => d.average_score),
           backgroundColor: 'rgba(54, 162, 235, 0.5)'
         }]
       }
     })
     charts.push(subjectChart)

     // Time Analytics Chart
     const timeChart = new Chart(timeChartRef.current, {
       type: 'line',
       data: {
         labels: timeRes.data.map(d => d.completion_date),
         datasets: [{
           label: 'Average Duration (minutes)',
           data: timeRes.data.map(d => d.avg_duration / 60),
           borderColor: 'rgb(75, 192, 192)'
         }]
       }
     })
     charts.push(timeChart)

     // Overall Stats Display
     const overallStats = new Chart(overallStatsRef.current, {
      type: 'pie',
      data: {
        labels: [
          'Completed',
          'Incomplete',
          'Avg Time (mins)'
        ],
        datasets: [{
          data: [
            statsRes.data.completion_rate,
            100 - statsRes.data.completion_rate,
            statsRes.data.average_time / 60 // Convert seconds to minutes
          ],
          backgroundColor: [
            'rgba(75, 192, 192, 0.5)',
            'rgba(255, 99, 132, 0.5)',
            'rgba(255, 206, 86, 0.5)'
          ]
        }]
      },
      options: {
        plugins: {
          tooltip: {
            callbacks: {
              label: function(context) {
                const label = context.label;
                const value = context.raw;
                if (label === 'Avg Time (mins)') {
                  return `Average Time: ${value.toFixed(2)} minutes`;
                }
                return `${label}: ${value.toFixed(2)}%`;
              }
            }
          }
        }
      }
    })
     charts.push(overallStats)
   }

   fetchData()

   return () => charts.forEach(chart => chart.destroy())
 }, [])

 return (
   <div className="row">
     <div className="col-12 mb-4">
       <h2>Analytics Dashboard for {user.full_name}</h2>
     </div>

     <div className="col-md-4">
       <div className="card bg-palette text-white">
         <div className="card-body">
           <h5 className="card-title">Completion Rate</h5>
           <canvas ref={overallStatsRef}></canvas>
         </div>
       </div>
     </div>

     <div className="col-md-8">
       <div className="card bg-palette text-white">
         <div className="card-body">
           <h5 className="card-title">Subject Performance</h5>
           <canvas ref={subjectChartRef}></canvas>
         </div>
       </div>
     </div>

     <div className="col-12 mt-4">
       <div className="card bg-palette text-white">
         <div className="card-body">
           <h5 className="card-title">Time Analytics</h5>
           <canvas ref={timeChartRef}></canvas>
         </div>
       </div>
     </div>
   </div>
 )
}

export default StudentDashboard
