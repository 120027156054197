import React, { useState, useEffect } from 'react';
import InspirationalQuote from './InspirationalQuote';

const QuizProgress = ({ quizGeneratorId, initialStatus, initialProgress }) => {
  const [progress, setProgress] = useState(initialProgress || 0);
  const [status, setStatus] = useState(initialStatus || 'in_progress');
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    let interval;

    const fetchProgress = () => {
      fetch(`/quiz_generators/${quizGeneratorId}/progress.json`, {
        headers: { 'Cache-Control': 'no-cache' },
      })
        .then(response => {
          if (!response.ok) throw new Error('Network response was not ok.');
          return response.json();
        })
        .then(data => {
          console.log('Fetched progress:', data.progress);
          setProgress(data.progress);
          setStatus(data.status);

          if (data.status === 'completed') {
            if (data.redirect_url) {
              window.location.href = data.redirect_url;
            } else {
              setErrorMessage('Quiz generation completed, but no redirect URL found.');
              clearInterval(interval);
            }
          } else if (data.status === 'failed') {
            setErrorMessage('Quiz generation failed. Please try again.');
            clearInterval(interval);
          }
        })
        .catch(error => {
          console.error('Error fetching progress:', error);
          setErrorMessage('An error occurred while fetching progress.');
          clearInterval(interval);
        });
    };

    interval = setInterval(fetchProgress, 1000);
    return () => clearInterval(interval);
  }, [quizGeneratorId]);

  return (
    <div className="container py-5">
      <div className="row justify-content-center">
        <div className="col-12 col-md-8">
          <div className="card bg-palette border-0 shadow-sm">
            <div className="card-body p-5">
              {/* Status Icons and Messages */}
              <div className="text-center mb-4">
                {status === 'in_progress' && (
                  <div className="generation-status">
                    <div className="status-icon bg-primary bg-opacity-10 rounded-circle mx-auto mb-4
                                  d-flex align-items-center justify-content-center"
                         style={{ width: '80px', height: '80px' }}>
                      <i className="fas fa-cog fa-spin text-primary fa-2x"></i>
                    </div>
                    <h2 className="h3 text-theme mb-2">Generating Your Quiz</h2>
                    <p className="text-theme-secondary mb-0">
                      Our AI is crafting your perfect quiz
                    </p>
                  </div>
                )}

                {status === 'completed' && (
                  <div className="completion-status">
                    <div className="status-icon bg-success bg-opacity-10 rounded-circle mx-auto mb-4
                                  d-flex align-items-center justify-content-center"
                         style={{ width: '80px', height: '80px' }}>
                      <i className="fas fa-check text-success fa-2x"></i>
                    </div>
                    <h2 className="h3 text-theme mb-2">Quiz Ready!</h2>
                    <p className="text-theme-secondary mb-0">
                      Redirecting you to your quiz...
                    </p>
                  </div>
                )}

                {status === 'failed' && (
                  <div className="error-status">
                    <div className="status-icon bg-danger bg-opacity-10 rounded-circle mx-auto mb-4
                                  d-flex align-items-center justify-content-center"
                         style={{ width: '80px', height: '80px' }}>
                      <i className="fas fa-exclamation-triangle text-danger fa-2x"></i>
                    </div>
                    <h2 className="h3 text-theme mb-2">Generation Failed</h2>
                    <p className="text-theme-secondary mb-0">{errorMessage}</p>
                  </div>
                )}
              </div>

              {/* Progress Section */}
              {status === 'in_progress' && (
                <div className="progress-section">
                  {/* Progress Bar */}
                  <div className="progress-container bg-darker rounded-pill p-1 mb-4">
                    <div className="progress" style={{ height: '20px' }}>
                      <div className="progress-bar bg-primary rounded-pill"
                           role="progressbar"
                           style={{
                             width: `${progress}%`,
                             transition: 'width 0.5s ease-in-out'
                           }}
                           aria-valuenow={progress}
                           aria-valuemin="0"
                           aria-valuemax="100">
                        <span className="progress-text">
                          {Math.floor(progress)}%
                        </span>
                      </div>
                    </div>
                  </div>

                  {/* Progress Steps */}
                  <div className="progress-steps row g-3 mb-4">
                    <div className="col-4">
                      <div className={`step-item text-center p-3 rounded
                                    ${progress >= 33 ? 'bg-success bg-opacity-10' : 'bg-darker'}`}>
                        <i className={`fas fa-file mb-2 ${progress >= 33 ? 'text-success' : 'text-theme-secondary'}`}></i>
                        <div className={progress >= 33 ? 'text-theme' : 'text-theme-secondary'}>
                          Analyzing Content
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className={`step-item text-center p-3 rounded
                                    ${progress >= 66 ? 'bg-success bg-opacity-10' : 'bg-darker'}`}>
                        <i className={`fas fa-brain mb-2 ${progress >= 66 ? 'text-success' : 'text-theme-secondary'}`}></i>
                        <div className={progress >= 66 ? 'text-theme' : 'text-theme-secondary'}>
                          Generating Questions
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className={`step-item text-center p-3 rounded
                                    ${progress >= 100 ? 'bg-success bg-opacity-10' : 'bg-darker'}`}>
                        <i className={`fas fa-check mb-2 ${progress >= 100 ? 'text-success' : 'text-theme-secondary'}`}></i>
                        <div className={progress >= 100 ? 'text-theme' : 'text-theme-secondary'}>
                          Finalizing Quiz
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Inspirational Quote */}
                  <div className="quote-section bg-darker rounded p-4">
                    <InspirationalQuote />
                  </div>
                </div>
              )}

              {/* Error Message */}
              {errorMessage && status !== 'failed' && (
                <div className="alert bg-danger bg-opacity-10 border-0 text-danger mt-4">
                  <div className="d-flex">
                    <i className="fas fa-exclamation-circle fs-4 me-3"></i>
                    <div>
                      <h6 className="alert-heading mb-1">Oops! Something went wrong</h6>
                      <p className="mb-0">{errorMessage}</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuizProgress;
