import React, { useEffect, useState } from "react";
import axios from 'axios';
import { setupAxiosCSRF } from '../../helpers';

const QuizTakingInterface = ({ quiz, questions, quiz_id, user }) => {
  const [selectedAnswer, setSelectedAnswer] = useState({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isQuizSubmitted, setIsQuizSubmitted] = useState(false);
  const totalTime = questions.reduce((total, question) => total + question.time_limit, 0);
  const [timeLeft, setTimeLeft] = useState(totalTime);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [answerSubmissionStatus, setAnswerSubmissionStatus] = useState({});
  const [cheatingWarning, setCheatingWarning] = useState(false);
  const [cheatingCount, setCheatingCount] = useState(0)
  const [shuffledAnswers, setShuffledAnswers] = useState([]);
  const totalQuestions = questions.length;
  const progress = ((currentQuestionIndex + 1) / totalQuestions) * 100;
  const DISPLAY_RADIUS = 5;
  const startQuestionIndex = Math.max(0, currentQuestionIndex - DISPLAY_RADIUS);
  const endQuestionIndex = Math.min(questions.length, currentQuestionIndex + DISPLAY_RADIUS + 1);
  const MAX_RETRIES = 3;
  const RETRY_DELAY = 3000;

  const handleAnswerSelection = (questionId, answerId) => {
    setSelectedAnswer(prevState => ({
      ...prevState,
      [questionId]: answerId
    }));

    setAnswerSubmissionStatus(prevStatus => ({
      ...prevStatus,
      [questionId]: 'saving'
    }));

    saveAnswerToServer(questionId, answerId);
  };

  const saveAnswerToServer = (questionId, answerId, retryCount = 0) => {
    setupAxiosCSRF();
    axios.post(`/quizzes/${quiz.id}/submit_answer`, {
      question_id: questionId,
      answer_id: answerId,
    })
    .then(response => {
      console.log("Answer saved successfully.", response.data);
      setAnswerSubmissionStatus(prevStatus => ({
        ...prevStatus,
        [questionId]: 'saved'
      }));
    })
    .catch(error => {
      console.log("Error saving answer.", error);

      // If the maximum retry count hasn't been reached, schedule a retry
      if (retryCount < MAX_RETRIES) {
        console.log(`Retrying in ${RETRY_DELAY / 1000} seconds...`);
        setTimeout(() => {
          saveAnswerToServer(questionId, answerId, retryCount + 1);
        }, RETRY_DELAY);
      } else {
        console.log("Max retries reached. Giving up.");
        setAnswerSubmissionStatus(prevStatus => ({
          ...prevStatus,
          [questionId]: 'error'
        }));
      }
    });
  };

  const handleQuestionJump = (questionIndex) => {
    if (questionIndex >= 0 && questionIndex < questions.length) {
      setCurrentQuestionIndex(questionIndex);
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const formatTime = (seconds) => {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = seconds % 60;
    return `${h}:${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`;
  };

  useEffect(() => {

    const handleContextMenu = (e) => {
      e.preventDefault();
      setCheatingWarning(true);
      setCheatingCount(prevCount => prevCount + 1);
    }

    const handleCopy = (e) => {
      setCheatingWarning(true);
      setCheatingCount(prevCount => prevCount + 1);
    }

    document.addEventListener('contextmenu', handleContextMenu);
    document.addEventListener('copy', handleCopy);

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
      document.removeEventListener('copy', handleCopy);
    }
  }, []);

  useEffect(() => {
    if (cheatingCount >= 5) {
      handleDisqualification();
    }
  }, [cheatingCount]);

  useEffect(() => {
    let countDownInterval;
    clearInterval(countDownInterval);
    countDownInterval = setInterval(() => {
      setTimeLeft(prevTime => {
        if (prevTime <= 1) {
          clearInterval(countDownInterval);
          handleSubmit();
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => {
      clearInterval(countDownInterval);
    };
  }, [currentQuestionIndex, questions]);

  useEffect(() => {
    // Function to shuffle answers
    function shuffle(array) {
      let currentIndex = array.length, temporaryValue, randomIndex;

      // While there remain elements to shuffle...
      while (0 !== currentIndex) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
      }

      return array;
    }

    // Shuffle answers for the current question
    if (questions && questions.length > 0 && questions[currentQuestionIndex]) {
      const shuffled = shuffle([...questions[currentQuestionIndex].answers]);
      setShuffledAnswers(shuffled);
    }
  }, [questions, currentQuestionIndex]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (!isQuizSubmitted) {
        e.preventDefault();
        e.returnValue = "Are you sure you want to leave? Your quiz will be submitted automatically.";
        handleDisqualification();
      }
      return e.returnValue;
    };

    if (!isQuizSubmitted) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isQuizSubmitted]);

  const handleSubmit = () => {
    setIsSubmitting(true);

    setupAxiosCSRF();
    axios.post(`/quizzes/${quiz_id}/finalize_submission`)
      .then(response => {
        console.log("Quiz submitted successfully.", response.data);
        setIsSubmitting(false);
        window.location.href = `/quizzes/${quiz_id}/result`;
      })
      .catch(error => {
        console.log("Error submitting answers.", error);
    });
    setIsQuizSubmitted(true);
  };

  const handleDisqualification = () => {
    setIsSubmitting(true);

    setupAxiosCSRF();
    axios.post(`/quizzes/${quiz_id}/disqualified`)
      .then(response => {
        console.log("Quiz disqualified successfully.", response.data);
        setIsSubmitting(false);
        alert('You have been disqualified and logged out.');
        window.location.href = '/users/sign_in';
      })
      .catch(error => {
        console.log("Error disqualifying quiz.", error);
    });
    setIsQuizSubmitted(true);
  }

  return (
    <div className="container-fluid py-4">
      {/* Quiz Header */}
      <div className="row justify-content-center mb-4">
        <div className="col-12 col-xl-8">
          <div className="card bg-palette border-0 shadow-sm">
            <div className="card-body p-4">
              <div className="d-flex justify-content-between align-items-start">
                <div>
                  <h1 className="h3 text-theme mb-2">{quiz.title}</h1>
                  <p className="text-theme-secondary mb-0">
                    <i className="fas fa-clipboard-list me-2"></i>
                    {questions.length} Questions
                  </p>
                </div>

                {/* Timer Badge */}
                <div className={`timer-badge rounded-pill px-4 py-2
                              ${timeLeft <= 10 ? 'bg-danger' : 'bg-primary'}
                              bg-opacity-10`}>
                  <div className="d-flex align-items-center gap-2">
                    <i className={`fas fa-clock
                                ${timeLeft <= 10 ? 'text-danger' : 'text-primary'}`}>
                    </i>
                    <span className={timeLeft <= 10 ? 'text-danger' : 'text-primary'}>
                      {formatTime(timeLeft)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="row justify-content-center g-4">
        {/* Question Navigation Sidebar */}
        <div className="col-12 col-md-3 col-xl-2">
          <div className="card bg-palette border-0 shadow-sm">
            <div className="card-header bg-palette border-bottom border-secondary p-3">
              <div className="d-flex align-items-center">
                <i className="fas fa-list-ol text-primary me-2"></i>
                <h6 className="text-theme mb-0">Questions</h6>
              </div>
            </div>

            <div className="card-body p-3">
              <div className="question-grid">
                {questions.map((q, index) => {
                  const isAnswered = !!selectedAnswer[q.id];
                  let statusClass = isAnswered ? 'answered' :
                                  (index === currentQuestionIndex ? 'current' : '');

                  return (
                    <button
                      key={index}
                      className={`question-number-btn ${statusClass}`}
                      onClick={() => handleQuestionJump(index)}
                      disabled={isAnswered && index !== currentQuestionIndex}
                    >
                      {index + 1}
                      {isAnswered && <i className="fas fa-check"></i>}
                    </button>
                  );
                })}
              </div>

              <div className="progress mt-3" style={{ height: '6px' }}>
                <div
                  className="progress-bar bg-success"
                  style={{ width: `${progress}%` }}
                  aria-valuenow={progress}
                  aria-valuemin="0"
                  aria-valuemax="100"
                />
              </div>

              <small className="text-theme-secondary d-block text-center mt-2">
                {currentQuestionIndex + 1} of {totalQuestions}
              </small>
            </div>
          </div>
        </div>

        {/* Main Question Area */}
        <div className="col-12 col-md-9 col-xl-6">
          <div className="card bg-palette border-0 shadow-sm">
            {/* Question Content */}
            <div className="card-header bg-palette border-bottom border-secondary p-4">
              <div className="question-header">
                <h5 className="text-theme mb-3">
                  Question {currentQuestionIndex + 1}
                </h5>
                <p className="text-theme mb-0">
                  {questions[currentQuestionIndex].content}
                </p>
              </div>

              {/* Cheating Warning */}
              {cheatingWarning && (
                <div className="alert bg-danger bg-opacity-10 border-0 mt-3">
                  <div className="d-flex">
                    <i className="fas fa-exclamation-triangle text-danger fs-4 me-3"></i>
                    <div>
                      <h6 className="text-danger mb-2">Warning: No Cheating!</h6>
                      <p className="text-danger mb-0">
                        You will be disqualified after {5 - cheatingCount} more attempts.
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Answer Options */}
            <div className="card-body p-4">
              <div className="answer-options">
                {shuffledAnswers.map(answer => (
                  <div key={answer.id} className="answer-option mb-3">
                    <div className={`answer-card ${
                      selectedAnswer[questions[currentQuestionIndex].id] === answer.id
                        ? 'selected'
                        : ''
                    }`}>
                      <input
                        type="radio"
                        name={`question_${questions[currentQuestionIndex].id}`}
                        id={`answer_${answer.id}`}
                        value={answer.id}
                        checked={selectedAnswer[questions[currentQuestionIndex].id] === answer.id}
                        onChange={() => handleAnswerSelection(questions[currentQuestionIndex].id, answer.id)}
                        disabled={!!selectedAnswer[questions[currentQuestionIndex].id]}
                        className="visually-hidden"
                      />
                      <label htmlFor={`answer_${answer.id}`} className="answer-label">
                        {answer.content}
                      </label>
                    </div>
                  </div>
                ))}

                {/* Answer Status */}
                {answerSubmissionStatus[questions[currentQuestionIndex].id] && (
                  <div className="answer-status mt-3">
                    {answerSubmissionStatus[questions[currentQuestionIndex].id] === 'saving' && (
                      <div className="text-warning">
                        <i className="fas fa-spinner fa-spin me-2"></i>
                        Saving your answer...
                      </div>
                    )}
                    {answerSubmissionStatus[questions[currentQuestionIndex].id] === 'saved' && (
                      <div className="text-success">
                        <i className="fas fa-check-circle me-2"></i>
                        Answer saved successfully
                      </div>
                    )}
                    {answerSubmissionStatus[questions[currentQuestionIndex].id] === 'error' && (
                      <div className="text-danger">
                        <i className="fas fa-exclamation-circle me-2"></i>
                        Failed to save answer
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>

            {/* Navigation Footer */}
            <div className="card-footer bg-palette border-top border-secondary p-3">
              <div className="d-flex justify-content-between align-items-center">
                <button
                  className="btn btn-outline-primary"
                  onClick={handlePreviousQuestion}
                  disabled={currentQuestionIndex === 0}
                >
                  <i className="fas fa-arrow-left me-2"></i>Previous
                </button>

                {Object.keys(selectedAnswer).length === questions.length ? (
                  <button
                    className="btn btn-success"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <div>
                        <i className="fas fa-spinner fa-spin me-2"></i>
                        Submitting...
                      </div>
                    ) : (
                      <div>
                        <i className="fas fa-check me-2"></i>
                        Submit Quiz
                      </div>
                    )}
                  </button>
                ) : (
                  <button
                    className="btn btn-outline-primary"
                    onClick={handleNextQuestion}
                    disabled={
                      currentQuestionIndex === questions.length - 1 ||
                      !selectedAnswer[questions[currentQuestionIndex].id]
                    }
                  >
                    Next<i className="fas fa-arrow-right ms-2"></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuizTakingInterface;
