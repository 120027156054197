import React, { useEffect, useRef } from 'react'
import Chart from 'chart.js/auto'

const StudentPerformance = ({ user }) => {
 const scoreDistributionRef = useRef(null)
 const quizTypeRef = useRef(null)
 const bestScoresRef = useRef(null)

 useEffect(() => {
  let charts = []

  const fetchData = async () => {
    const performanceRes = await fetch('/api/v1/analytics/student_performance/performance_metrics').then(r => r.json())
    const data = performanceRes.data

     // Score Distribution Chart
     charts.push(new Chart(scoreDistributionRef.current, {
      type: 'bar',
      data: {
        labels: ['0-10%', '11-20%', '21-30%', '31-40%', '41-50%', '51-60%', '61-70%', '71-80%', '81-90%', '91-100%'],
        datasets: [{
          label: 'Number of Attempts',
          data: Object.values(data.score_distribution),
          backgroundColor: 'rgba(75, 192, 192, 0.5)'
        }]
      },
      options: {
        plugins: {
          title: { display: true, text: 'Score Distribution' }
        }
      }
    }))

    // charts.push(new Chart(quizTypeRef.current, {
    //   type: 'pie',
    //   data: {
    //     labels: data.quiz_type_performance.map(qt => qt.quiz_type_label),
    //     datasets: [{
    //       data: data.quiz_type_performance.map(qt => parseFloat(qt.avg_score).toFixed(2)),
    //       backgroundColor: ['rgba(54, 162, 235, 0.5)', 'rgba(255, 99, 132, 0.5)']
    //     }]
    //   }
    // }))

    charts.push(new Chart(bestScoresRef.current, {
      type: 'bar',
      data: {
        labels: data.best_scores.map(score => score.quiz_title),
        datasets: [{
          label: 'Score (%)',
          data: data.best_scores.map(score => score.percentage_score),
          backgroundColor: 'rgba(54, 162, 235, 0.5)'
        }]
      },
      options: {
        plugins: {
          title: { display: true, text: `Best Scores (Pass Rate: ${data.pass_rate}%)` }
        },
        scales: {
          y: {
            beginAtZero: true,
            max: 100
          }
        }
      }
    }))
  }

   fetchData()
   return () => charts.forEach(chart => chart.destroy())
 }, [])

 return (
   <div>
     <h2>Performance Analysis for {user.full_name}</h2>
     <div className="row mt-4">
       <div className="col-12">
         <div className="card mb-4 bg-palette text-white">
           <div className="card-body">
            <h5 className="card-title">Number of attempts &amp; Score range</h5>
             <canvas ref={scoreDistributionRef}></canvas>
           </div>
         </div>
       </div>
       {/* <div className="col-md-6">
         <div className="card mb-4">
           <div className="card-body">
             <canvas ref={quizTypeRef}></canvas>
           </div>
         </div>
       </div> */}
       <div className="col-12">
         <div className="card bg-palette text-white">
           <div className="card-body">
              <h5 className="card-title">Top 10 Best Scores</h5>
             <canvas ref={bestScoresRef}></canvas>
           </div>
         </div>
       </div>
     </div>
   </div>
 )
}

export default StudentPerformance
