import React, { useEffect, useRef } from 'react'
import Chart from 'chart.js/auto'

const ComparisonAnalytics = ({ user }) => {
 const subjectComparisonRef = useRef(null)
 const difficultyComparisonRef = useRef(null)
 const percentileRef = useRef(null)

 useEffect(() => {
   let charts = []

   const fetchData = async () => {
     const comparisonRes = await fetch('/api/v1/analytics/student_performance/comparison_metrics').then(r => r.json())
     const data = comparisonRes.data

     // Subject Comparison Chart
     charts.push(new Chart(subjectComparisonRef.current, {
       type: 'bar',
       data: {
         labels: data.subject_comparison.map(item => item.category),
         datasets: [
           {
             label: 'Your Average',
             data: data.subject_comparison.map(item => item.user_average),
             backgroundColor: 'rgba(75, 192, 192, 0.5)'
           },
           {
             label: 'Class Average',
             data: data.subject_comparison.map(item => item.class_average),
             backgroundColor: 'rgba(255, 99, 132, 0.5)'
           }
         ]
       },
       options: {
         plugins: { title: { display: true, text: 'Subject Performance Comparison' } }
       }
     }))

     // Difficulty Comparison Chart
     charts.push(new Chart(difficultyComparisonRef.current, {
       type: 'bar',
       data: {
         labels: data.difficulty_comparison.map(item => item.category.toUpperCase()),
         datasets: [
           {
             label: 'Your Average',
             data: data.difficulty_comparison.map(item => item.user_average),
             backgroundColor: 'rgba(75, 192, 192, 0.5)'
           },
           {
             label: 'Class Average',
             data: data.difficulty_comparison.map(item => item.class_average),
             backgroundColor: 'rgba(255, 99, 132, 0.5)'
           }
         ]
       },
       options: {
         plugins: { title: { display: true, text: 'Performance by Difficulty Level' } }
       }
     }))

     // Percentile Chart
     charts.push(new Chart(percentileRef.current, {
       type: 'doughnut',
       data: {
         labels: ['Your Percentile', 'Remaining'],
         datasets: [{
           data: [data.score_percentile.percentile, 100 - data.score_percentile.percentile],
           backgroundColor: ['rgba(75, 192, 192, 0.5)', 'rgba(255, 99, 132, 0.5)']
         }]
       },
       options: {
         plugins: {
           title: { display: true, text: 'Your Score Percentile' },
           tooltip: {
             callbacks: {
               label: function(context) {
                 if (context.dataIndex === 0) {
                   return `You are in the top ${100 - context.raw}%`;
                 }
                 return '';
               }
             }
           }
         }
       }
     }))
   }

   fetchData()
   return () => charts.forEach(chart => chart.destroy())
 }, [])

 return (
   <div>
     <h2>Performance Comparison for {user.full_name}</h2>
     <div className="row mt-4">
       <div className="col-md-6">
         <div className="card mb-4 bg-palette">
           <div className="card-body">
             <canvas ref={subjectComparisonRef}></canvas>
           </div>
         </div>
       </div>
       <div className="col-md-6">
         <div className="card mb-4 bg-palette">
           <div className="card-body">
             <canvas ref={difficultyComparisonRef}></canvas>
           </div>
         </div>
       </div>
       <div className="col-12">
         <div className="card bg-palette">
           <div className="card-body">
             <canvas ref={percentileRef}></canvas>
           </div>
         </div>
       </div>
     </div>
   </div>
 )
}

export default ComparisonAnalytics
